.filters {
    width: 100%;
    margin-bottom: 40px;
}

.filter .filter__input.text>.tr-flex,
.filter .filter__input.multi-select>.tremor-base,
.filter .filter__input.date-picker>.tremor-base>.tr-flex,
.filter .filter__input.select>.tremor-base {
    border-radius: 16px;
}

.filter .filter__input.text>.tr-flex>input,
.filter .filter__input.multi-select .tremor-base>button,
.filter .filter__input.date-picker .tremor-base>.tr-flex>button,
.filter .filter__input.select .tremor-base>.tr-flex>input {
    border: none;
    outline: none;
    background-color: var(--white);
    font-size: 14px;
    padding: 13px 16px;
    border-radius: 16px;
    border-color: unset;
}

.filter .filter__input.disabled label,
.filter .filter__input.disabled .tremor-base {
    cursor: not-allowed;
    opacity: 0.5;
}

.filter .filter__input.disabled .tremor-base>button {
    pointer-events: none;
}

.filter .filter__input.select .tremor-base>.tr-flex>input {
    cursor: pointer;
}

.filter .filter__input.multi-select .tremor-base>.tr-absolute input[type="checkbox"] {
    margin-right: 10px;
    border: 1px solid #e5e7eb;
}

.filter .filter__input.multi-select .tremor-base>.tr-absolute input[type="checkbox"]::after {
    width: 4px;
    height: 10px;
}

.hover\:tr-bg-gray-50:hover {
    opacity: 1;
    background-color: var(--white);
}

.filters .filters__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    background-color: var(--white);
    box-shadow: 0px 8px 16px #013DEE1A;
    border-radius: 54px;
    width: 100%;
}

.filters.filters--open .filters__toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
}

.filters .filters__label {
    display: flex;
    align-items: center;
}

.filters .filters__icon {
    margin-right: 10px;
}

.filters .filters__icon svg,
.filters .filters__arrow svg {
    vertical-align: middle;
}

.filters.filters--open .filters__arrow {
    transform: rotate(180deg);
}

.filters .filters__title {
    color: var(--violet);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.filters .filters__dropdown {
    position: relative;
    display: none;
    padding: 48px 24px 24px 24px;
    background-color: #FFFFFF99;
    box-shadow: 0px 8px 16px #013DEE1A;
    border: 1px solid #FFFFFF;
    border-bottom-right-radius: 26px;
    border-bottom-left-radius: 26px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    z-index: 2;
}

.filters.filters--open .filters__dropdown {
    display: block;
}

.filters .filter__row {
    display: flex;
    margin-bottom: 35px;
}

.filters .filter__row:nth-child(2) {
    margin-bottom: 50px;
}

.filters .filter__row:last-child {
    justify-content: flex-end;
    margin-bottom: 0;
}

.filters .filter__row:last-child .cta:first-child {
    margin-right: 10px;
}

.filters .filter__input {
    width: 100%;
    margin-right: 30px;
}

.filters .filter__input label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-blue);
    margin-bottom: 10px;
}


.filters .filter__input>.tremor-base {
    border: none;
}

.filters .filter__input:last-child {
    margin-right: 0;
}