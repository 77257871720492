#pzo__sidebar {
    height: 100%;
    width: 225px;
    position: fixed;
    left: 0;
    top: 0;
    padding: 10px 15px 10px 10px;
    background-color: transparent;
    border-right: 1px solid #16236A1A;
    margin: 15px 0;
    overflow: hidden;
}

#pzo__sidebar .pzo__sidebar-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

#pzo__sidebar .pzo__logo {
    display: block;
    padding: 0 10px;
}

#pzo__sidebar .pzo__logo .logo-full {
    display: block;
    width: 160px;
    height: 36px;
    background-repeat: no-repeat;
}

#pzo__sidebar ul {
    margin-top: 40px;
}

#pzo__sidebar .pzo__sidebar-user-btns li,
#pzo__sidebar ul li a {
    cursor: pointer;
    color: var(--dark-blue);
    font-weight: 500;
    padding: 15px 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    transition: all 0.4s ease-in-out;
    border-radius: 16px;
}

#pzo__sidebar .pzo__sidebar-user-btns li {
    margin-bottom: 0;
}

#pzo__sidebar ul li a.active {
    color: var(--blue);
    background: #ffffff;
    box-shadow: -5px 0px 10px #00000000;
    opacity: 1;
}

#pzo__sidebar .pzo__sidebar-user-btns li:hover,
#pzo__sidebar ul li a:not(.active):hover {
    background: rgba(255, 255, 255, 0.35);
}

#pzo__sidebar .pzo__sidebar-user-btns .pzo__icon,
#pzo__sidebar ul li a .pzo__icon {
    margin-right: 15px;
}

.pzo__sidebar-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 110px);
}

/* Closed Sidebar */

.sidebar--closed #pzo__sidebar {
    width: 70px;
    padding: 10px;
}

.sidebar--closed #pzo__sidebar .pzo__logo {
    padding: 0;
}

.sidebar--closed #pzo__sidebar .pzo__logo .logo-full {
    width: 36px;
    margin: auto;
    background-image: url(../images/svgs/paparazzo_logo_icon.svg);
}

.sidebar--closed #pzo__sidebar .pzo__nav-link {
    display: none;
}

.sidebar--closed #pzo__sidebar .pzo__sidebar-user-btns .pzo__icon,
.sidebar--closed #pzo__sidebar ul li a .pzo__icon {
    margin: auto;
}

.sidebar--open #pzo__sidebar ul li a {
    animation: sidebar-content 0.75s ease 0s 1 normal forwards;
}

@keyframes sidebar-content {
    0% {
        opacity: 0;
        transform: translateY(25px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}