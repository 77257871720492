.testate__table .tr-overflow-auto {
    overflow: unset;
}

.testata-title__row {
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.testata-title__container {
    min-width: 600px;
}

.testata-title__row .testata-title__toggle svg {
    width: 16px;
    margin-left: 8px;
}

.testata-title__row:hover .testata-title__title,
.testata-title__row:hover .testata-title__toggle svg {
    color: var(--blue);
    stroke: var(--blue)
}

.testata-title__container .testata-title__childs {
    display: none;
}

.testata-title__container.active .testata-title__childs {
    display: block;
    margin-top: 20px;
}

.testata-title__container.active .testata-title__toggle svg {
    transform: rotate(180deg);
}

.testata-title__container .testata-title__child {
    display: flex;
    align-items: center;
    gap: 0 10px;
    margin: 10px 0;
}

.testata-title__container .testata-title__child svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: var(--red);
}

.testata-title__container .testata-title__child input {
    width: 100%;
    outline: none;
    padding: 8px 16px;
    background: rgb(255, 255, 255, 65%);
    box-shadow: 0px 10px 20px #1C296E0D;
    border-radius: 12px;
}

.testata-select .tremor-base {
    width: 170px;
    border: unset;
    box-shadow: 0px 5px 10px #16236A14;
    border-radius: 12px;
}

.testata-select .tremor-base input {
    cursor: pointer;
    color: #16236A80;
}

.testata-title__add-url {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 20px 0 10px 0;
}

.testata-title__add-url svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    fill: #66DBC8;
}

.testata-title__add-url span {
    opacity: 0.7;
    font-size: 12px;
}

.testate-form__form .row label {
    margin-right: 20px;
    min-width: 80px;
}