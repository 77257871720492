:root {
  --white: #FFFFFF;
  --text-color: #374151;
  --dark-blue: #16236A;
  --blue: #013DEE;
  --violet: #6B6CF9;
  --red: #FF632F;
  --green: #66DBC8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #E1EBFB;
  background-image: url(./commons/images/paparazzo-bkg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 
*
* ==== Typrography ==== *
*
*/

h1 {
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -9999px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.h1,
.h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

h2,
.h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
}

h3,
.h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

p {
  font-size: 14px;
  line-height: 1.5;
}

/* 
*
* ==== Anchors ==== *
*
*/

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

a:hover {
  color: var(--blue);
}

/* 
*
* ==== Ctas ==== *
*
*/

.cta {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 10px 22px !important;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  background: transparent linear-gradient(90deg, #013DEE 0%, #6A6CF9 100%) 0% 0% no-repeat padding-box;
  border-radius: 56px !important;
}

.cta svg {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.cta.cta--outlined {
  padding: 9px 21px;
  color: var(--blue);
  background: unset;
  background-color: transparent;
  border: 1px solid var(--blue);
}

.cta.cta-negative {
  background: var(--white);
  background-color: var(--white);
  color: var(--blue);
  box-shadow: 0px 20px 30px #16236A4D;
}

/* 
*
* ==== Lists ==== *
*
*/

ul li {
  list-style-type: none;
}

/* 
*
* ==== Icons ==== *
*
*/

.pzo__icon {
  display: block;
  width: 22px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Logos */

.logo-full {
  background-image: url(./commons/images/svgs/paparazzo_logo.svg);
}

.logo {
  background-image: url(./commons/images/svgs/paparazzo_logo_icon.svg);
}

/* Sidebar icons */
.icon-home {
  background-image: url(./commons/images/svgs/icon-home.svg);
}

.icon-badge {
  background-image: url(./commons/images/svgs/icon-badge.svg);
}

.icon-folder {
  background-image: url(./commons/images/svgs/icon-folder.svg);
}

.icon-newspaper {
  background-image: url(./commons/images/svgs/icon-newspaper.svg);
}

.icon-users {
  background-image: url(./commons/images/svgs/icon-users.svg);
}

.icon-account {
  background-image: url(./commons/images/svgs/icon-account.svg);
}

.icon-exit {
  background-image: url(./commons/images/svgs/icon-exit.svg);
}

/* Sidebar icons - blue version */
#pzo__sidebar ul li a.active .icon-home {
  background-image: url(./commons/images/svgs/icon-home-blue.svg);
}

#pzo__sidebar ul li a.active .icon-badge {
  background-image: url(./commons/images/svgs/icon-badge-blue.svg);
}

#pzo__sidebar ul li a.active .icon-folder {
  background-image: url(./commons/images/svgs/icon-folder-blue.svg);
}

#pzo__sidebar ul li a.active .icon-newspaper {
  background-image: url(./commons/images/svgs/icon-newspaper-blue.svg);
}

#pzo__sidebar ul li a.active .icon-users {
  background-image: url(./commons/images/svgs/icon-users-blue.svg);
}

#pzo__sidebar ul li a.active .icon-account {
  background-image: url(./commons/images/svgs/icon-account-blue.svg);
}

/* 
*
* ==== Common classes ==== *
*
*/


.bg-blue-gradient {
  background: linear-gradient(160deg, rgba(1, 61, 238, 1) 20%, rgba(107, 108, 249, 1) 90%);
}

.bg-white {
  background-color: var(--white);
}

.bg-transparent-white {
  background-color: #FFFFFF99;
}

.bradius-bshadow {
  box-shadow: 0px 20px 30px #013DEE26;
  border-radius: 26px;
  padding: 25px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {

  input[type="checkbox"],
  input[type="radio"] {
    --active: #66DBC8;
    --active-inner: #f4f4ed;
    --focus: 2px #f4f4ed;
    --border: #80806f;
    --border-hover: #2222223d;
    --background: #ffffff;
    --disabled: #f6f8ff67;
    --disabled-inner: #e1e6f986;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 20px;
    width: 20px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #ffffff;
    background: var(--b, var(--background));
    -webkit-transition: background 0.3s, border-color 0.3s, -webkit-box-shadow 0.2s;
    transition: background 0.3s, border-color 0.3s, -webkit-box-shadow 0.2s;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    border-radius: 3px;
  }

  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    -webkit-transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  }

  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    border: 1px solid #66DBC8;
  }

  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }

  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }

  input[type="checkbox"]:disabled+label,
  input[type="radio"]:disabled+label {
    cursor: not-allowed;
  }

  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }

  input[type="checkbox"]+label,
  input[type="radio"]+label {
    font-size: 16px;
    line-height: 120%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  input[type="checkbox"]:after {
    width: 6px;
    height: 11px;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 2px;
    -webkit-transform: rotate(var(--r, 20deg));
    transform: rotate(var(--r, 20deg));
  }

  input[type="checkbox"]:checked {
    --r: 43deg;
  }

  input[type="radio"] {
    border-radius: 50%;
  }

  input[type="radio"]:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
    opacity: 1;
    -webkit-transform: scale(var(--s, 0.7));
    transform: scale(var(--s, 0.7));
  }

  input[type="radio"]:checked {
    --s: 0.5;
  }
}